import frameworks from 'assets/images/impact/frameworks.png'
import esgInitiatives from 'assets/images/impact/esg-initiatives.png'
import portfolioManagement from 'assets/images/impact/portfolio-management.png'
import mornington from "assets/images/portfolio/mornington.png"
import wollonggong from "assets/images/portfolio/wollonggong.png"
import AnalyzeIcon from 'assets/icons/AnalyzeIcon'
import PlanIcon from 'assets/icons/PlanIcon'

export const impacts = [
  {
    image: frameworks,
    title: "Framework",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/"
  },
  {
    image: esgInitiatives,
    title: "ESG Initiatives",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/"
  },
  {
    image: portfolioManagement,
    title: "Portfolio Management",
    description: "Lorem ipsum dolor sit amet consectetur.",
    href: "/"
  },
]

const whyUsTopContent = [
  {
    icon: AnalyzeIcon,
    title: "Global E-sport Revenue",
    description: "The potential of this nascent industry is huge. Dominated by a young, digital and global audience, the esports industry is fueled with economic opportunities measured by sponsorships, merchandising, ticket sales, advertising and media rights."
  },
  {
    icon: PlanIcon,
    title: "SEA Esports Market Outlook",
    description: "Since 2016, Indonesia placed 2nd on the esports growing market. 2017 was when the esports market in Indonesia grew drastically. Today, Indonesia is one of the leading country for Esports in SEA Region."
  }
]

const whyUsSecondContent = [
  {
    icon: AnalyzeIcon,
    title: "+75 Million",
    description: "Indonesian are gamers"
  },
  {
    icon: PlanIcon,
    title: "60% of Indonesian",
    description: "own smartphones and 17% of them are active gamers"
  },
  {
    icon: AnalyzeIcon,
    title: "14-35 years old",
    description: "from those are active gamers"
  },
  {
    icon: PlanIcon,
    title: "60% of Indonesian",
    description: "have consumtive behavior towards their hobby"
  },
  {
    icon: AnalyzeIcon,
    title: "8 hours a day",
    description: "Millenials spend their time online"
  },
  {
    icon: PlanIcon,
    title: "6 hours a week",
    description: "Millenials spend their time playing games"
  },
  {
    icon: AnalyzeIcon,
    title: "12 hours a week",
    description: "Millenials and other age group halved their TV viewership over the past 7 years"
  }
]

export const whyUs = {
  topContent: (
    <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8'>
      {whyUsTopContent.map((step, i) => {
        const Icon = step.icon
        return (
          <div key={i} className='space-y-4'>
            <div className='space-y-2 app-animation-translate-start'>
              <Icon />
              <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
            </div>
            <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
          </div>
        )
      })}
    </div>
  ),
  secondContent: (
    <div className='app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8'>
      {whyUsSecondContent.map((step, i) => {
        const Icon = step.icon
        return (
          <div key={i}>
            <div className='space-y-2 app-animation-translate-start'>
              <Icon />
              <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
            </div>
            <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
          </div>
        )
      })}
    </div>
  ),
  ourServices: [
    {
      id: 1,
      title: 'E-Sports consulting',
      link_url: '/esports-consulting',
      image_url: 'https://images.pexels.com/photos/3165335/pexels-photo-3165335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 2,
      title: 'E-Sports event organizer',
      link_url: '/esports-eo',
      image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/sl-logo.jpeg'
    },
    {
      id: 3,
      title: 'Production house specializing in OTT content and social media content',
      link_url: '/production-house',
      image_url: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 4,
      title: 'Digital marketing agency',
      link_url: '/digital-marketing',
      image_url: 'https://images.pexels.com/photos/5678243/pexels-photo-5678243.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 5,
      title: 'E-Sports team',
      link_url: '/esports-team',
      image_url: 'https://assets.skor.id/crop/136x76:1228x690/x/photo/2020/10/05/1364398555.jpg'
    }
  ]
}

export const about = {
  description: (
    <>
      <p className='app-animation-translate-start'>
        Our company was established in 2017 with the goal of developing esports ecosystem in Indonesia. We first started with Supreme League Arena, becoming the first cybercafe with ultimate esports ready hardware ( 240hz refresh rate monitors and top of the line CPU & GPU ) ensuring that our customers have the competitive edge in gaming.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        Today, our company has more than evolved from a small cybercafe to having a multitude of services in order to develop esports.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        We are the first company to create esports programs for television in Indonesia. Trusted by a cable television for more than 25 hours worth of esports content a month. We turn gaming into an entertainment watched by millions.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        As an esports event organizer we have done more than 100 events including big names from Tencent, Bytedance and trusted by internationally acclaimed companies to guide them in successfully engaging with the esports Indonesian market.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        We also developed an esports team in 2018 with the name Alterego Esports and in less than a year we are considered as one of the top 3 Indonesian esports team.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        In the everchanging esports industry we help our clients keep up with the trends and on ways to capitalize on the millions of millennial games, using esports as a gateway to reach this lucrative demographic.
      </p>
      <br />
      <p className='app-animation-translate-start'>
        Our mission is to promote electronic sports as the next iteration of sports. Our vision is to turn esports into the biggest form of entertainment.
      </p>
    </>
  ),
  our_role_img: [{
    original: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png',
    thumbnail: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png'
  }]
}

export const structure = {
  organization_structure_img: [{
    original: 'https://storage.googleapis.com/nizerteklintasbumi/images/Hirarki_Draft.jpeg',
    thumbnail: 'https://storage.googleapis.com/nizerteklintasbumi/images/Hirarki_Draft.jpeg'
  }]
}

export const generalBrandsLogo = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/bardi-logo.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/blackshark-logo.webp'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/boncabe-logo.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/bri-logo.webp'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/gojek-logo.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/line-logo.png'
  },
  {
    id: 7,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/mandiri-logo.webp'
  },
  {
    id: 8,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/realme-logo.webp'
  },
  {
    id: 9,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png'
  },
  {
    id: 10,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/samsung-logo.svg'
  },
  {
    id: 11,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ticketcom-logo.png'
  }
]

export const sponsoredByLogo = [
  {
    id: 1,
    name: 'Rivera',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/fd6f745375ce57889e33e87b08ced2db71569d412d4992b234f1380333b08e91d80de66df776208d9f36fc32e48083bbb663498ad0f1b9b1b52a2f5bac364091f1bf01331b2d9c3169cda7142d2f24243a69d058c1673edcf01c104a808c3be5e2c825544e98d72d4c22e2988b1b7ef85fcd8d8dee214acb7db6/rez-file-1736504998431.jpg'
  },
  {
    id: 2,
    name: 'BAIC',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/a44b39088e9e93d3d6b43a46bd8343f99797f4e26f8800b751df7f6b32c639f91a8255daae538232d8e6a64b925660b6683d8e101ec7fe3754e2eeb9dc403a3ee9e98c17fcacdfe591b9c01338dc2b994f96770b958ea55623635abe8bed6886d812e3f944d6d2a3e13057f8996bd56982b8ddb9813aa829cce1/file-1736416060170.png'
  },
  {
    id: 3,
    name: 'Wonhae',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/107f6e55aeaf27c3eff99d59791800f06e512e197afac610e6fa816a280901070d5a13dcb36fc94da4bbc3a971e961e59bc5c5a5aca556f4dc57b285d9b8a4cb39c9e6abada7823d5c6ff191f7eaa65262194ef7c6951a8014976a4fe8b80e672f36802ddbfa0784f346faad79ceef9753b5aec3af2439f4ef4e/rez-file-1736416098311.png'
  },
  {
    id: 4,
    name: 'VOLT',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/d7aa09593bd6defaf2d20e55bc3a777a285d47590ca325ad884a75b770bc187fb5c52b8234ccc09f8209ff2e74e5a92f7d6316aa5285eeb5ac2d3d2c1dd487a51936a20c35d96c2bb6bb1cbe9674f9421657c2cda0838eefb1f15a7a24ecbd054f66cde6a3d98dba933b9214f2ab85cbbe3b2be61db59c5b56e3/rez-file-1736505022816.jpg'
  },
  {
    id: 6,
    name: 'Top Golf',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/d93fcf168457146b88cd35ee99e42821ee2c04f470cc0ccd0ff5f6d3f36e05166007c0cc54c10c6f1ca1e1c8bf6496a75381b1fee51f675c863854e6e2f5280c3dbbdbcc8f9a28539523363f2ed9caf95a575d3af34d74288e751515c4dfe9be5fe95aec67a97407d7754652c5c6192610dab50c33757d973df6/file-1736837507270.png'
  },
  {
    id: 7,
    name: 'Rajawali Prost',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/1fb6a6484ee3064ebe4c828c1ff0f7e30f22a1b581756011a7189cd75bab91237aa21053f4bd43c0890a43fb69ac55fcc905acac8648f74c6f22ff0f0c79c63575ef32167c0108dc65226b606b4676d517bd51e8cb684e3e0ab25fef0967bd26d31c863819b98ddc7e7b3c08ca61edc483a9ecc6cb9b06995eb4/rez-file-1736505103376.png'
  },
  {
    id: 8,
    name: 'Konig',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/5a921375146c304e2b666fad00dfb8f3a01b61c09533b25556e59e5d8bd96d69223f74f334e94ec85dcc7a19d7f73bf39a3cc0223cb148d422605e20e94e8f51ea2441e2e5e81b77f90522d01c0de8deabb831c78ffda40395e3f67f4e068ec582b4dc57bf487ae6f993168f801f45e0284dc3871e52d1d3ec69/rez-file-1736505135132.png'
  },
  {
    id: 11,
    name: 'PLN Insurance',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/fb03d5f4f239b5682ec4c2b5a43f5718adfe10b7be0aa04feb16635aa2c7dba3183479a83c71fa026dbfbf0d1e47809901a7c8f353da0c625e373fea0b70a5558488a598bb6eff022cbc967088aff7e5596b792f45c3b366bddf576f4ebd87bd54050bf154175bb41a1d8942f0405dd5c06723b72a52e67b778a/file-1737473038508.jpg'
  },
  {
    id: 12,
    name: 'Paramatech First',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/c7edfb6f2251437592e07c5cee2589d25dc3214f4ceac117bd40fcd23366bbeaf5561fbedf0b166a4e6173ddbc0b623cf755fc4a58eacb9f73e65504cb73e0a376ef33f282e13cb412d91c68d515ddbd4ad838e0f8b583c6f02ed13f04b56a53a60c26d5c7a2764f9b6e2a4a5d9abe32fd3d9d3b315c914d89b6/file-1737473071358.jpeg'
  }
]

export const supportedByLogo = [
  {
    id: 1,
    name: 'BFW',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/393945a72d1ca3aa43ae417d3294e6d68c80ef9ff43e2d73d721c3da3d48585ccd50940b995b7708cb1bd1d3d7f441b4da172202310ffd43970b4ce2ea34d687ff85a88cc6239867b2316dd04d108f72c5efdcc1b09a16dba3402e2d3b6f97c22728d5f9f04c709e2fee9c64c9b0dcbecb771e17173d5d519caa/file-1718955444636.png'
  },
  {
    id: 2,
    name: 'BXPO',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/4a2f7f24389289dc082095c1e0cec634701da43d226c7e47a556859f6f6a03657df5fc405100e2704959a50ff4ea0b28a463fd9806927b8816a7a841f94b66dfee1945839f3344cac5da46d826fec1b713d7ea0b7ecad9e88712400c096fd47c3290f4fc632a815e6a110ddb4c94243d7148051e792110297ca6/file-1719205287022.PNG'
  },
  {
    id: 3,
    name: 'Emo Trans',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/5138bd6613fd957503bfd103fc16bba59ff6b63ec7bb4b32e2e714dc43d08bce6f329ba3f73f482ab0d861bda947ed6d3b1efdd7617f190a55d66a4a78c2d3d261baa67afc9299405a6e7ed546d1a876e6e9951b5fcbb4a191a5e6bf3d54d93fb80eb66dc4143cf4a6d5d993618101efd7fb1050418d9eb47dbe/file-1718956188015.png'
  },
  {
    id: 4,
    name: 'Merchmaniacs',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/3337b89357a4c23e99c45d460ed790a47b9bc938599a7300fa2806aa62d8128073f36a89568f6fb9348ff454574d7a59ce288ee46ad8e1d1bf6c4d7cf09ca0c503c06021fdf98a33e2f5a0bc04dfed6dcebc13a177bea8ff513db02dfa9244d874078c62d32cb82e3e13600d9244efe3197ca8512808436ceadc/file-1718955647557.png'
  },
  {
    id: 5,
    name: 'BIG',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/2faa0888469544c76468cf492634907ef6f5d3e1721092c69be82d0413ae22ad06c64615edc34b58d1b49d4a2ca7132b3c4cb904e0eac7c20bc13e290018c02dc6c4b8d1412fcf43fc11d8e1425ca072b75be1e6d076db91b8f0ed8da54c8a98513602a67a9fe302f8ed224d529e4c428adbdb363ec219fac1d4/rez-file-1736416232340.png'
  }
]

export const presentedByLogo = [
  {
    id: 1,
    name: 'BIG Event Asia',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bab0156cfcbd877f006cec71e21e68086442529a73cef3e7dbeb7d8d13895d28412dc447faf601ba595936346738fe0efc66d42120e85245c311247c09c39d58976906ba647d24935fe6ad5d18e22e884bf57bd1faac7361aaeff3c69fd44f53f9a38fa03a2a2f50038b1047d5ba8a151ae6ae054ff746ba7226/rez-file-1736416276911.png'
  },
  {
    id: 2,
    name: 'Surya Indah',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/92103c849261ea5902ffbefd4d414f5d776d25e98e8b009472fd24fab8e857e9c328e4366e2e226c6f1fd0c8f3fc76914ebfc216fe379d5cebaafb10ea8b4e94d3c78f37e39d1e716b5216edcb558e89b1f2fe0063bc6252ca6255dbccd7213b81d9457c9f89258a8e0ffe53c474cf8bc4bc03ea72ddcd232a07/file-1736416034633.png'
  }
]

export const communityPartnerLogo = [
  {
    id: 1,
    name: 'El Golf',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/9ddda4dd7a20f1fdc89d326f9f2b50cf8a63222da2d55441a545bd6ac4046f86679278e358865b3ae04c84a7591e3a60c008d2ec944a48667771666dcae2bf63712a9a89263af60ec4cee8e629c54782efa5bb644a32f353a82f4a43437bd3939d8947196a0865345f00e5214649543902dbb2e5cec20ab7d3a4/file-1737473186814.jpeg'
  },
  {
    id: 2,
    name: 'ISAA',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/87aa52f4388b55f85b6cbafbfcefae58dc394e60c04a5bf2f1a451dbdec99b2d916c0f63e7581176c622f8aeada5630cea13b8402adbe24852b8d39ec503c9614af7104399392396f236b0373a3cf8de49531154a3517b4244d10513a83ae69d39762c1adbd9490ec27f225f04a19e24d3fc50800976ca1d109d/file-1737473228614.jpg'
  },
  {
    id: 3,
    name: 'NBGG',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/447f79990c1a40afa4a55ce4a7a32f2c1a6a09197699b48db68f65afd08251657c9448a429af1c5feb8a3f4e3c726ed6e58dd3bc6fc0dbfc638329a6843ba1dd2af98efe60c5c2ffae53f706e3b08b92add9218034dfe51a95668d4217ce67701329d5fea62996c4959ad813e75336311a5c8266e8ef44d3ecbb/file-1737473260734.png'
  },
  {
    id: 4,
    name: 'Perpeju',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/a70937dca8e26ed662413ecca0143c929f5cc9ed58e19486b8bd1327f53365d16e47bb2dac0e1fdb25ceefb72436d1ce651da5c8133aefe71d855417db2bcfc069ea320fa971a0d1ce6699903d1157e59f4c772f2c06000d2b8f9edb911f338dca575512d0bfdfe155afb424c0cec2214bc513c4e35474d37b89/file-1737473286197.jpeg'
  },
  {
    id: 5,
    name: 'Riverside Golf Club Blitar',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/12870efacd54d436d82ca03b92205a3021f612c98b08beffe34ec0d3db36a94a148e1748e7ffa14480f47d0bac1e8d46488ca22e9b80c2b73754c6112c82b80dc1c43c337ddd1bd0cbeea798869b3af4dc1ebb0eaf05125cbe01b52cedabe5102226799ee63f4ec8db741b27800975270f75e79a6fb4895ae46c/file-1737473317769.png'
  }
]

export const ticketingByLogo = [
  {
    id: 1,
    name: 'Cloudxier',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/b4d1290c6711cf20d5dda63611a56f04b15bdc1129f5ea3a848bdbdf3055ab024e14b3664244785c33015dd74314e79b9d18d8ee9d2dede39cb088937d8deb58999411a12605891e67a9109bd15d9fe536ece3a3f4a1b89903ef0639066f74d9c56152fffb8f5ca1e1a3705dd37dc0de70b33c52291bd78adaaf/file-1718956888002.png'
  }
]

export const djPerformByLogo = [
  {
    id: 1,
    name: 'Ada Band',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/bef8766f2df01fcd9dac9d743349c8a5a1c5f6e4f88ee726986940929762b2e064da0eeda43ea4cbfe6c291532a4f15f720b1803a385de19eec82f1f81db3ebe96e9b3d6737e838d9e2c756df67d4d0924e3462f62004cb8080d5ab83d38965d0f080f82881d5efa63bf564640208158fef96eb522f7f2a71a02/rez-file-1736416350731.jpg'
  },
]

export const venueByLogo = [
  {
    id: 1,
    name: 'Ciputra Golf',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/e05aeb8d1870f7d83bd300a63adbe0c9872d83a4cb1c6ccda58b8728e514bcc6a86e6088c2941ae9729d5c43dd8ad9f862bb3bf9255072a0219b3f0849aae75340ce408f7e9f7516042835a9da487eba825be222b00a56037587b6d6c1ff2ec8faba04e3c1fbfe8348028920c3bac0d736386e7a8634a50c38db/rez-file-1736416382841.png'
  }
]

export const mediaByLogo = [
  {
    id: 1,
    name: 'Golf Trovert',
    image_url: 'https://storage.googleapis.com/testing_storage_cloudxier/cloudxier-images/3bca45d4ca0d3995f04c4ca6df983f68bfc9b33a7272e672dd564868c66210b08fd6950cb61de11e2679e45e40e118009a05b7fcf436d96e75862341b1928775d8e73eea38b41cf8b467b4c90da920c21c341cacc712009c22a83bdb12612d075c2ea4cc60de0827ab7b73b4a30667079b1f31716d375950187e/file-1718957191805.jpg'
  }
]

export const gamePublishersLogo = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/gamevil-logo.jpeg'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/garena-logo.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/igg-logo.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/linepod-logo.png'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/megaxus-logo.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/mihoyo-logo.svg'
  },
  {
    id: 7,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/moonton-logo.png'
  },
  {
    id: 8,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/riot-logo.png'
  },
  {
    id: 9,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png'
  },
  {
    id: 10,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp'
  },
  {
    id: 11,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/vng-logo.png'
  }
]

export const ottContentBrand = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/snackvideo-logo.png'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/useetv-logo.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png'
  }
]

export const internetProvidersBrand = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/axis-logo.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/indihome-logo.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/myrepublic-logo.webp'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png'
  }
]

export const phClientsLogo = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png'
  },
  {
    id: 7,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png'
  },
  {
    id: 8,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png'
  }
]

export const phVideoBanner = [
  {
    id: 1,
    title: 'Love in Game',
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/banner/love-in-game-banner.png',
    link_url: 'https://maxstream.tv/movies/0_xp5n50sz'
  }
]

export const phYoutubeVideoLinks = [
  {
    id: 1,
    title: 'PUBG Mobile Jawara Community',
    video_url: 'https://www.youtube.com/embed/iM--q-HAIUI?si=lIrzxVxp2F229AE_'
  },
  {
    id: 2,
    title: 'AOC Valentine Campaign',
    video_url: 'https://www.youtube.com/embed/ZT2XhYtwrYs?si=P_8tWcb332nFepAE'
  },
  {
    id: 3,
    title: 'AGON by AOC Campaign',
    video_url: 'https://www.youtube.com/embed/V_Nrj3FSKnw?si=WjzvnV5_BpVfm8RA'
  }
]

export const phGdriveVideoLinks = [
  {
    id: 1,
    title: 'E-Sports Time',
    video_url: 'https://drive.google.com/file/d/1rgdpg_yzKCPxw98SFB5Yk4fEJ-XkERQG/preview'
  },
  {
    id: 2,
    title: 'Mabar Yuk!',
    video_url: 'https://drive.google.com/file/d/1c__-g-W5yImfNGURE3Eaaqu162yFQ9hh/preview'
  },
  {
    id: 3,
    title: 'Forgotten Path',
    video_url: 'https://drive.google.com/file/d/1OjPGg0Vd5AZW3WE6-ORaHOUDoAwbWP9X/preview'
  }
]


export const phStudioImages = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const phStudio2Images = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const phDigitalAssetImages = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const publishingPrClients = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/firstmedia-logo.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/vidio-logo.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/maxstream-logo.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/tencent-logo.webp'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/game-publishers/supercell-logo.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/ott-content/nimotv-logo.png'
  },
  {
    id: 7,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/internet-providers/telkomsel-logo.png'
  },
  {
    id: 8,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/redbull-logo.png'
  }
]

export const technoMediaPartnerLogo = [
  {
    id: 1,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/carisinyal.png'
  },
  {
    id: 2,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/dormatekno.png'
  },
  {
    id: 3,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/droidlime.png'
  },
  {
    id: 4,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/duerclockingid.png'
  },
  {
    id: 5,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadetapa.png'
  },
  {
    id: 6,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgetdiva.png'
  },
  {
    id: 7,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgetsquad.png'
  },
  {
    id: 8,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gadgettren.png'
  },
  {
    id: 9,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gawaimikro.png'
  },
  {
    id: 10,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gayafone.png'
  },
  {
    id: 11,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/gizmologi.png'
  },
  {
    id: 12,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/hardwareholic.png'
  },
  {
    id: 13,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/hittekno.png'
  },
  {
    id: 14,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/infokom.png'
  },
  {
    id: 15,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/inilahcom.png'
  },
  {
    id: 16,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/jagatreview.png'
  },
  {
    id: 17,
    image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/kincir.png'
  },
  {
    "id": 18,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/mbittekno.png"
  },
  {
    "id": 19,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/nextren.png"
  },
  {
    "id": 20,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/pricebook.png"
  },
  {
    "id": 21,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/pulsa.png"
  },
  {
    "id": 22,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/review.png"
  },
  {
    "id": 23,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/selular.png"
  },
  {
    "id": 24,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/skor.png"
  },
  {
    "id": 25,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/techbiz.png"
  },
  {
    "id": 26,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/techdaily.png"
  },
  {
    "id": 27,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/technologue.png"
  },
  {
    "id": 28,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/tekid.png"
  },
  {
    "id": 29,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/teknogav.png"
  },
  {
    "id": 30,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/telkoid.png"
  },
  {
    "id": 31,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/trenteknologi.png"
  },
  {
    "id": 32,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/yangcanggih.png"
  },
  {
    "id": 33,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/techno/youngster.png"
  }
]

export const gamingMediaPartnerLogo = [
  {
    "id": 1,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/d.png"
  },
  {
    "id": 2,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/duniagames.png"
  },
  {
    "id": 3,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/duniaku.png"
  },
  {
    "id": 4,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/esportsid.png"
  },
  {
    "id": 5,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/esportsku.png"
  },
  {
    "id": 6,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamebrott.png"
  },
  {
    "id": 7,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamedaim.png"
  },
  {
    "id": 8,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gamefever.png"
  },
  {
    "id": 9,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gameholic.png"
  },
  {
    "id": 10,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/ggwpid.png"
  },
  {
    "id": 11,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gridgames.png"
  },
  {
    "id": 12,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/gudanggaming.png"
  },
  {
    "id": 13,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/hybrid.png"
  },
  {
    "id": 14,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/indoesports.png"
  },
  {
    "id": 15,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/indogames.png"
  },
  {
    "id": 16,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/jagatplay.png"
  },
  {
    "id": 17,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kabargames.png"
  },
  {
    "id": 18,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kaorinusantara.png"
  },
  {
    "id": 19,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kincir.png"
  },
  {
    "id": 20,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/kotakgame.png"
  },
  {
    "id": 21,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/mastergame.png"
  },
  {
    "id": 22,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/onesports.png"
  },
  {
    "id": 23,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/revivaltv.png"
  },
  {
    "id": 24,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/skorid.png"
  },
  {
    "id": 25,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/skyegrid.png"
  },
  {
    "id": 26,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/gaming/yodu.png"
  }
]

export const generalMediaPartnerLogo = [
  {
    "id": 1,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/akuratco.png"
  },
  {
    "id": 2,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/alix.png"
  },
  {
    "id": 3,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/antv.svg.png"
  },
  {
    "id": 4,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/beritasatu.png"
  },
  {
    "id": 5,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/bisnisindonesia.png"
  },
  {
    "id": 6,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/canggihid.png"
  },
  {
    "id": 7,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/cnn.svg.png"
  },
  {
    "id": 8,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/dailysocialid.png"
  },
  {
    "id": 9,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/detikcom.png"
  },
  {
    "id": 10,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/jawapos.png"
  },
  {
    "id": 11,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/jpnn.png"
  },
  {
    "id": 12,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kapanlagi.svg.png"
  },
  {
    "id": 13,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompas.png"
  },
  {
    "id": 14,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompascom.png"
  },
  {
    "id": 15,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kompastekno.png"
  },
  {
    "id": 16,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/kumparan.png"
  },
  {
    "id": 17,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/liputan6.svg.png"
  },
  {
    "id": 18,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/marketeers.png"
  },
  {
    "id": 19,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/merahputih.png"
  },
  {
    "id": 20,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/merdekacom.png"
  },
  {
    "id": 21,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/okezone.png"
  },
  {
    "id": 22,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/rakyatmerdeka.png"
  },
  {
    "id": 23,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/republika.png"
  },
  {
    "id": 24,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/sindonews.png"
  },
  {
    "id": 25,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/suaracom.png"
  },
  {
    "id": 26,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tagarid.png"
  },
  {
    "id": 27,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tempo.png"
  },
  {
    "id": 28,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/tvone.png"
  },
  {
    "id": 29,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/uzone.png"
  },
  {
    "id": 30,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/viva.png"
  },
  {
    "id": 31,
    "image_url": "https://storage.googleapis.com/nizerteklintasbumi/logo/media-partner/general/wartaekonomi.png"
  }
]

export const promotionalImages = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const marketingCatImages = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const dMarketingCatImages = [
  {
    original: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/668296/pexels-photo-668296.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    original: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
    thumbnail: 'https://images.pexels.com/photos/2228831/pexels-photo-2228831.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
]

export const aeImages = [
  {
    original: 'https://cdn.vcgamers.com/news/wp-content/uploads/2023/02/Logo-Alterego-1.jpg',
    thumbnail: 'https://cdn.vcgamers.com/news/wp-content/uploads/2023/02/Logo-Alterego-1.jpg',
    description: 'Alter Ego 1'
  },
  {
    original: 'https://awsimages.detik.net.id/community/media/visual/2022/08/03/ini-dia-roster-baru-alter-ego-esports-di-mpl-id-s10_169.png?w=1200',
    thumbnail: 'https://awsimages.detik.net.id/community/media/visual/2022/08/03/ini-dia-roster-baru-alter-ego-esports-di-mpl-id-s10_169.png?w=1200',
    description: 'Alter Ego 2'
  },
  {
    original: 'https://d1tgyzt3mf06m9.cloudfront.net/v3-staging/2023/08/230827-PMSL-SEA-Fall-2023-Alter-Ego-Juara.png',
    thumbnail: 'https://d1tgyzt3mf06m9.cloudfront.net/v3-staging/2023/08/230827-PMSL-SEA-Fall-2023-Alter-Ego-Juara.png',
    description: 'Alter Ego 3'
  }
]

export const portfolio = [
  {
    id: 1,
    image: mornington,
    name: "Mornington peninsula villas",
    description: (
      <>
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [
      mornington,
      mornington
    ],
    section2Photos: [
      mornington,
      mornington
    ],
    tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
  },
  {
    id: 2,
    image: wollonggong,
    name: "Wollonggong private residence",
    description: (
      <>
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [
      wollonggong,
      wollonggong
    ],
    section2Photos: [
      wollonggong,
      wollonggong
    ],
    tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
  },
  {
    id: 3,
    image: mornington,
    name: "Mornington peninsula villas",
    description: (
      <>
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [
      mornington,
      mornington
    ],
    section2Photos: [
      mornington,
      mornington
    ],
    tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
  },
  {
    id: 4,
    image: wollonggong,
    name: "Wollonggong private residence",
    description: (
      <>
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
        <br />
        <p className='app-animation-translate-start'>
          Lorem ipsum dolor sit amet consectetur.Id magna convallis diam condimentum felis convallis.Sed faucibus sagittis a sed commodo fermentum massa.Mauris venenatis lacus at semper.Purus tortor massa ac ultrices gravida in.Viverra ipsum in viverra nisl dignissim gravida vitae.Scelerisque sagittis sem dignissim eleifend ac.
        </p>
      </>
    ),
    section1Photos: [
      wollonggong,
      wollonggong
    ],
    section2Photos: [
      wollonggong,
      wollonggong
    ],
    tagline: "Modern living reimagined. Bringing the balance for luxury and minimalist."
  },
]

export const posts = [
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
  },
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
  },
  {
    image: mornington,
    title: "Mornington peninsula villas",
    description: "Lorem ipsum dolor sit amet consectetur. Id magna convallis diam condimentum felis convallis. "
  },
]


